import { Component, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ThemeService } from '@app/core/services/theme.service';
import { CreateOrEditDialogComponent } from '@app/shared/components/create-or-edit-dialog/create-or-edit-dialog.component';
import { GameInstance } from '@data/models/game-instance.model';
import { GameService } from '@data/services/game.service';
import { InstanceService } from '@data/services/instance.service';
import { SnackbarService } from '@shared/services/snackbar.service';
import { ChartConfiguration, ChartData } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { environment as env } from '@env';
import { Promotion } from '../../../data/models/promotion.model';
import { City } from '../../../data/models/city.model';

@Component({
  selector: 'app-game-and-instance-stats',
  templateUrl: './game-and-instance-stats.component.html',
  styleUrl: './game-and-instance-stats.component.scss',
})
export class GameAndInstanceStatsComponent {

  demoOrgId = env.demoOrgId;

  demo: boolean = false;

  // Variables
  yearLabels: any = {
    '01': 'Janvier',
    '02': 'Février',
    '03': 'Mars',
    '04': 'Avril',
    '05': 'Mai',
    '06': 'Juin',
    '07': 'Juillet',
    '08': 'Août',
    '09': 'Septembre',
    '10': 'Octobre',
    '11': 'Novembre',
    '12': 'Décembre',
  };

  filterLabels: any = {
    yesterday: 'Hier',
    today: "Aujourd'hui",
    month: 'Mois',
    week: 'Semaine',
    year: 'Année',
    custom: 'Personnalisé',
  };

  xpRank = [
    {
      first_name: 'John',
      last_name: 'Doe',
      xp: Math.round(Math.random() * 1000),
    },
    {
      first_name: 'Jane',
      last_name: 'Smith',
      xp: Math.round(Math.random() * 1000),
    },
    {
      first_name: 'John',
      last_name: 'Smith',
      xp: Math.round(Math.random() * 1000),
    },
    {
      first_name: 'Jane',
      last_name: 'Doe',
      xp: Math.round(Math.random() * 1000),
    },
  ];

  headerLabel = '';

  dateRange = new FormGroup({
    start: new FormControl(null), // Vous pouvez initialiser avec une date par défaut si nécessaire
    end: new FormControl(null), // Vous pouvez initialiser avec une date par défaut si nécessaire
  });

  // CHARTS 📊
  @ViewChildren(BaseChartDirective) charts?: QueryList<BaseChartDirective>;

  // Chart - Line
  public lineChartData: ChartConfiguration['data'] = {
    labels: [],
    datasets: [
      {
        label: 'Nombre de niveaux réalisés',
        data: [],
        fill: true,
        backgroundColor: 'rgba(30,255,255,0.2)',
        borderColor: '#3dfff9',
        pointBackgroundColor: '#7bfff4',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(93,130,190,0.8)',
        yAxisID: 'y',
      },
      {
        label: 'Temps moyen passé par niveau',
        data: [],
        backgroundColor: 'rgba(0,188,144,0.2)',
        borderColor: '#00c39a',
        pointBackgroundColor: '#4dd9be',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        fill: 'origin',
        yAxisID: 'y1',
      },
    ],
  };
  public lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.5,
      },
    },
    scales: {
      y: {
        max: undefined,
        position: 'left',
        min: 0,
        title: {
          display: true,
          text: 'Nombre de niveaux',
        },
        grid: {
          display: false,
        },
        ticks: {
          color: this.themeService.mode ? 'white' : 'black',
          stepSize: 0,
          callback: function (value, index, ticks) {
            return `${value}`;
          }
        },
      },
      y1: {
        max: undefined,
        min: 0,
        position: 'right',
        title: {
          display: true,
          text: 'Temps en minutes',
        },
        grid: {
          display: false,
        },
        ticks: {
          color: this.themeService.mode ? 'white' : 'black',
          stepSize: 0,
          callback: function (value, index, ticks) {
            // Value is in minutes
            const minutes = Math.floor((value as number));
            const seconds = (((value as number) % 1) * 60);
            if (minutes > 0) {
              if (seconds > 0) {
                return `${minutes.toFixed(0)} min ${seconds.toFixed(0)} sec`;
              } else {
                return `${minutes.toFixed(0)} min`;
              }
            } else {
              return `${seconds.toFixed(0)} sec`;
            }
          }
        },
      },
      x: {
        position: 'bottom',
        ticks: {
          color: this.themeService.mode ? 'white' : 'black',
        },
        grid: {
          display: false,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        labels: {
          color: this.themeService.mode ? 'white' : 'black',
        },
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            if (context.datasetIndex == 1) {
              // Value is in minutes
              const value = context.raw;
              const minutes = Math.floor((value as number));
              const seconds = (((value as number) % 1) * 60);
              if (minutes > 0) {
                if (seconds > 0) {
                  context.formattedValue = `${minutes.toFixed(0)} min ${seconds.toFixed(0)} sec`;
                } else {
                  context.formattedValue = `${minutes.toFixed(0)} min`;
                }
              } else {
                context.formattedValue = `${seconds.toFixed(0)} sec`;
              }
            }
          },
        },
      },
    },
  };

  // Chart - Pie
  public pieChartData: ChartData<'pie', number[], string | string[]> = {
    labels: ['Modules commencés', 'Modules terminés', 'Modules non commencés'],
    datasets: [
      {
        data: [800, 300, 100],
        backgroundColor: ['#6949FF', '#3dfff9', 'orange'],
        hoverBackgroundColor: ['#6949FF', '#3dfff9', 'orange'],
        hoverOffset: 30,
      },
    ],
  };
  public pieChartOptions: ChartConfiguration['options'] = {
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      datalabels: {
        formatter: (value, ctx) => {
          let sum: any = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map((data) => {
            sum += data;
          });
          let percentage = ((value * 100) / sum).toFixed(2) + '%';
          return percentage;
        },
        color: '#fff',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            context.formattedValue += ' parties';
          },
        },
      },
    },
    layout: {
      padding: {
        top: 35,
        bottom: 35,
      },
    },
  };

  countByEndedAt: { [key: string]: { totalDuration: number, averageDuration: number, count: number } } = {};
  showInstancesStats = false;
  showInstanceBar = false;
  gameId: string = '';
  instanceId: string = '';
  gameInstances: GameInstance[] = [];

  uniqueInstance: GameInstance | undefined = undefined;
  instancePromos: Promotion[] | undefined = undefined;
  instanceCities: City[] | undefined = undefined;
  selectedPromos: string[] | undefined = undefined;
  selectedCities: string[] | undefined = undefined;

  selectedView = 'month';


  constructor(
    public themeService: ThemeService,
    public gameService: GameService,
    public route: ActivatedRoute,
    public instanceService: InstanceService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    // Subscribe to route parameters to get gameId and instanceId
    this.route.params.subscribe((params) => {
      if (params['gameId']) {
        this.gameId = params['gameId'];
        this.gameService.getOne(this.gameId).subscribe((game) => {
          if (game.organisation.id === this.demoOrgId) {
            this.demo = true
          }

          if (params['instanceId']) {
            this.instanceId = params['instanceId'];

            this.showInstanceBar = false;
            this.showInstancesStats = true;

            this.getUniqueInstance(this.instanceId);
          } else {
            this.showInstanceBar = true;
          }
          this.setChartsColors();
          this.updateFiltersData(this.selectedView); // Set by default Month

        });
      }


    });
  }

  getUniqueInstance(instanceId: string) {
    this.instanceService.getGameInstanceAsManager(this.gameId, instanceId).subscribe((instance: GameInstance) => {
      this.gameInstances = [instance];
      this.uniqueInstance = instance;
      this.selectedCities = instance.cities?.map((city) => city.id);
      this.instanceCities = instance.cities;
      this.selectedPromos = instance.promotions?.map((promo) => promo.id);
      this.instancePromos = instance.promotions;
      this.getInstanceStats(instance);
    });
  }

  removeUniqueInstance() {
    this.uniqueInstance = undefined;
    this.selectedCities = undefined;
    this.instanceCities = undefined;
    this.selectedPromos = undefined;
    this.instancePromos = undefined;
  }

  selectPromo(promo: Promotion) {
    if (this.selectedPromos?.includes(promo.id)) {
      this.selectedPromos = this.selectedPromos?.filter((p) => p !== promo.id);
    } else {
      this.selectedPromos?.push(promo.id);
    }
    this.getInstanceStats(this.uniqueInstance!, this.selectedCities, this.selectedPromos);
  }

  selectCity(city: City) {
    if (this.selectedCities?.includes(city.id)) {
      this.selectedCities = this.selectedCities?.filter((c) => c !== city.id);
    } else {
      this.selectedCities?.push(city.id);
    }
    this.getInstanceStats(this.uniqueInstance!, this.selectedCities, this.selectedPromos);
  }

  /**
   * Remove Instance
   * @param instance
   */

  removeInstance(instance: GameInstance) {
    this.gameInstances = this.gameInstances.filter((i) => i.id !== instance.id);
    this.updateFiltersData(this.selectedView);
    if (this.gameInstances.length == 1) {
      this.showInstancesStats = true;
      this.getInstanceStats(this.gameInstances[0]);
      this.getUniqueInstance(this.gameInstances[0].id);
    } else {
      this.showInstancesStats = false;
      this.removeUniqueInstance();
    }
  }

  getInstanceStats(instance: GameInstance, cities?: string[], promos?: string[]) {
    if (this.gameInstances.length != 1) {
      this.showInstancesStats = false;
    } else {
      this.showInstancesStats = true;
      this.getLastLevelUnlocked(this.gameId, instance.id, cities, promos);
      this.getInstanceRanking(this.gameId, instance.id, cities, promos);

      if (this.selectedView === 'custom') {
        this.updateFiltersData(
          this.selectedView,
          this.dateRange.get('start')?.value ?? '',
          this.dateRange.get('end')?.value ?? '',
          cities,
          promos
        );
      } else {
        this.updateFiltersData(this.selectedView, undefined, undefined, cities, promos);
      }
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CreateOrEditDialogComponent, {
      width: '700px',
      data: {
        field: [
          {
            key: 'instances',
            type: 'autocomplete-chip',
            className: 'md:flex-grow',
            defaultValue: [],
            props: {
              label: 'Rechercher des sessions',
              placeholder: 'Rechercher par nom',
              required: false,
              multiple: false,
              labelProp: (instance: GameInstance) => instance.name,
              filter: (search: any) =>
                this.gameService.searchGameInstances(this.gameId, search),
            },
          },
        ],
        model: {},
        config: {
          header: {
            icon: '',
            label: 'Ajouter une session',
          },
          actions: {
            cancel: 'Annuler',
            submit: 'Valider',
          },
        },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let instances = result.instances;
        for (let instance of instances) {
          if (!this.gameInstances.find((i) => i.id === instance.id)) {
            this.gameInstances.push(instance);
          }
        }

        if (this.gameInstances.length == 1) {
          this.showInstancesStats = true;
          this.getInstanceStats(this.gameInstances[0]);
          this.getUniqueInstance(this.gameInstances[0].id);
        } else {
          this.showInstancesStats = false;
          this.removeUniqueInstance();
        }

        this.updateFiltersData(this.selectedView);
      }
    });
  }

  /**
   * Get Game Played Level By Date
   * @param gameId
   * @param startDate
   * @param endDate
   * @param instances
   */

  getGamePlayedLevelByDate(gameId: string, startDate: string, endDate: string, cities?: string[] | undefined, promos?: string[] | undefined) {

    if (this.demo) {
      this.generateFakeData(startDate, endDate);
      return;
    }

    let instances: string[] = [];
    if (!this.instanceId) {
      instances = this.gameInstances.map((i) => i.id);
    } else {
      instances.push(this.instanceId);
    }

    this.gameService
      .getGameLevelPlayedByDate(gameId, startDate, endDate, instances, cities, promos)
      .subscribe((response) => {
        this.processResponseData(response, this.countByEndedAt);

        this.countByEndedAt = this.reverseObjectKeys(this.countByEndedAt);

        // Update Line Chart Data
        this.lineChartData.labels = Object.keys(this.countByEndedAt);
        this.lineChartData.datasets[0].data = Object.keys(this.countByEndedAt).map(date => this.countByEndedAt[date].count);
        this.lineChartData.datasets[1].data = Object.keys(this.countByEndedAt).map(date => this.countByEndedAt[date].averageDuration);

        // Update Charts
        this.charts?.forEach((child) => {
          child.chart?.update();
        });
      });
  }


  processResponseData(response: any, dataObj: any) {

    response.forEach((data: any) => {
      let key: string;

      if (this.selectedView === 'year') {
        key = this.formatDateToMonth(data);
      } else {
        key = this.formatDate(data);
      }

      if (dataObj[key]) {

        dataObj[key].count++;
        if (data.duration) {
          if (data.duration.milliseconds) {
            dataObj[key].totalDuration += data.duration.milliseconds / 60000;
            if (data.duration.seconds) {
              dataObj[key].totalDuration += data.duration.seconds / 60;
              if (data.duration.minutes) {
                dataObj[key].totalDuration += data.duration.minutes;
              }
            }
          }
        } else if (data.created_at && data.ended_at) {
          const createdDate = new Date(data.created_at);
          const endedDate = new Date(data.ended_at);
          dataObj[key].totalDuration += (endedDate.getTime() - createdDate.getTime()) / 60000;
        }
      }
    });

    Object.keys(dataObj).forEach((date: string) => {
      if (dataObj[date].count != 0) {
        dataObj[date].averageDuration = dataObj[date].totalDuration / dataObj[date].count;
        dataObj[date].averageDuration = parseFloat((dataObj[date].totalDuration / dataObj[date].count).toFixed(2));
      }
    });

  }


  /**
   * Get Last Level Unlocked
   * @param gameId
   * @param instanceId
   */

  getLastLevelUnlocked(gameId: string, instanceId: string, cities?: string[] | undefined, promos?: string[] | undefined) {

    if (this.demo) {
      this.pieChartData.datasets[0].data = [];
      this.pieChartData.datasets[0].data.push(Math.round(Math.random() * 10));
      this.pieChartData.datasets[0].data.push(Math.round(Math.random() * 8));
      this.pieChartData.datasets[0].data.push(Math.round(Math.random() * 6));
      return;
    }

    this.gameService
      .getGameLastLevelUnlocked(gameId, instanceId, cities, promos)
      .subscribe((response) => {

        // Update Pie Chart Data
        this.pieChartData.datasets[0].data = [];
        this.pieChartData.datasets[0].data.push(
          response.playersDoingLastUnlockedUnit
        );
        this.pieChartData.datasets[0].data.push(
          response.playersHavingFinishedLastUnlockedUnit
        );
        this.pieChartData.datasets[0].data.push(
          response.playersNotAtLastUnlockedUnit
        );

        // Update Charts
        this.charts?.forEach((child) => {
          child.chart?.update();
        });
      });
  }

  /**
   * Get Instance Ranking
   * @param gameId
   * @param instanceId
   */

  getInstanceRanking(gameId: string, instanceId: string, cities?: string[] | undefined, promos?: string[] | undefined) {
    if (this.demo) {
      this.xpRank.sort((a: any, b: any) => b.xp - a.xp);
      return;
    }
    this.instanceService
      .getRanking(gameId, instanceId, cities, promos)
      .subscribe((response) => {
        this.xpRank = [];
        response.forEach((user: any) => {
          this.xpRank.push({
            first_name: user.participant.first_name,
            last_name: user.participant.last_name,
            xp: user.xp,
          });
        });
      });
  }

  initializePlayerData(dataObj: any, currentDate?: Date, startDate?: Date, endDate?: Date) {

    if (startDate && endDate) {


      const days = Math.floor((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));

      for (let i = 0; i < days; i++) {
        let day = new Date();
        day.setDate(endDate.getDate() - i);
        const dayFormatted = day.getDate().toString().padStart(2, '0');
        const monthFormatted = (day.getMonth() + 1).toString().padStart(2, '0');

        dataObj[`${dayFormatted}/${monthFormatted}`] = {
          totalDuration: 0,
          averageDuration: 0,
          count: 0
        };
      }

    } else if (currentDate) {

      const days = this.selectedView === 'month' ? 30 : 7;
      for (let i = 0; i < days; i++) {
        let day = new Date();
        day.setDate(currentDate.getDate() - i);
        const dayFormatted = day.getDate().toString().padStart(2, '0');
        const monthFormatted = (day.getMonth() + 1).toString().padStart(2, '0');

        dataObj[`${dayFormatted}/${monthFormatted}`] = {
          totalDuration: 0,
          averageDuration: 0,
          count: 0
        };
      }
    }

  }

  initializePlayerDateForYear(dataObj: any, currentDate: Date) {
    for (let i = 0; i < 12; i++) {
      let month = new Date();
      month.setMonth(currentDate.getMonth() - i);

      const monthFormatted = month.toLocaleString('fr-FR', { month: 'short' });

      dataObj[`${monthFormatted}`] = {
        totalDuration: 0,
        averageDuration: 0,
        count: 0
      };
    }

  }

  reverseObjectKeys(obj: any) {
    let reverseObj: any = {};
    Object.keys(obj).reverse().forEach(key => {
      reverseObj[key] = obj[key];
    });
    return reverseObj;
  }

  formatDate(data: any): string {
    let date = new Date();
    date = new Date(data.ended_at ? data.ended_at : data.created_at);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');

    return `${day}/${month}`;
  }

  formatDateToMonth(data: any): string {
    let date = new Date();
    date = new Date(data.ended_at ? data.ended_at : data.created_at);
    const month = date.toLocaleString('fr-FR', { month: 'short' });
    return month;
  }




  /*  -------------------------------- 🔍 FILTERS --------------------------------  */

  /**
   * Update Filters Data
   * @param type
   * @param start_date
   * @param end_date
   */


  updateFiltersData(type: string, start_date?: string, end_date?: string, cities?: string[], promos?: string[]) {

    this.countByEndedAt = {};
    const currentDate = new Date();
    const lastDay = new Date();
    const firstDay = new Date();

    switch (type) {
      case 'week':
        this.selectedView = 'week';
        this.initializePlayerData(this.countByEndedAt, currentDate);

        firstDay.setDate(lastDay.getDate() - 6);

        const formattedFirstDayOfTheWeek = firstDay.toISOString().split('T')[0];
        const formattedLastDayOfTheWeek = lastDay.toISOString().split('T')[0];

        this.getGamePlayedLevelByDate(this.gameId, formattedFirstDayOfTheWeek, formattedLastDayOfTheWeek, cities, promos);

        break;

      case 'month':
        this.selectedView = 'month';
        this.initializePlayerData(this.countByEndedAt, currentDate);

        firstDay.setDate(lastDay.getDate() - 30);

        const formattedFirstDayOfTheMonth = firstDay.toISOString().split('T')[0];
        const formattedLastDayOfTheMonth = lastDay.toISOString().split('T')[0];

        this.getGamePlayedLevelByDate(this.gameId, formattedFirstDayOfTheMonth, formattedLastDayOfTheMonth, cities, promos);

        break;

      case 'year':
        this.selectedView = 'year';
        this.initializePlayerDateForYear(this.countByEndedAt, currentDate);

        firstDay.setMonth(lastDay.getMonth() - 11);

        const formattedFirstDayOfTheYear = firstDay.toISOString().split('T')[0];
        const formattedLastDayOfTheYear = lastDay.toISOString().split('T')[0];

        this.getGamePlayedLevelByDate(this.gameId, formattedFirstDayOfTheYear, formattedLastDayOfTheYear, cities, promos);

        break;

      case 'custom':
        if (start_date && end_date) {
          this.selectedView = 'custom';
          this.initializePlayerData(this.countByEndedAt, currentDate, new Date(start_date), new Date(end_date));

          const startDate = new Date(start_date);
          const endDate = new Date(end_date);

          const formattedStartDate = startDate.toISOString().split('T')[0];
          const formattedEndDate = endDate.toISOString().split('T')[0];

          this.getGamePlayedLevelByDate(this.gameId, formattedStartDate, formattedEndDate, cities, promos);
        }
        break;

      default:
        break;
    }


  }


  /*  -------------------------------- / 🔍 FILTERS --------------------------------  */

  /*  -------------------------------- 🛠️ Tools --------------------------------  */
  /**
   * On Close Custom Date Window, update dateRange FormGroup
   */
  pickerClosed() {
    if (
      this.dateRange.get('start')?.value &&
      this.dateRange.get('end')?.value
    ) {
      this.updateFiltersData(
        'custom',
        this.dateRange.get('start')?.value ?? '',
        this.dateRange.get('end')?.value ?? ''
      );
    }
  }

  /**
   * Test if inputString is a Date
   * @param inputString
   * @returns
   */
  isDateFormatValid(inputString: string) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(inputString);
  }

  /**
   * Return timestamp in a specific format
   * @param type ( custom / today / month / year)
   * @param date
   * @returns
   */
  getSplit(type: string, date: any) {
    switch (type) {
      case 'today':
        return date.toISOString().split('T')[1].split(':')[0];
        break;

      case 'all':
      case 'week':
      case 'month':
      case 'custom':
        return date.toISOString().split('T')[0];
        break;

      case 'year':
        return date.toISOString().split('T')[0].split('-')[1];
        break;

      default:
        return date.toISOString().split('T')[1].split(':')[0];
        break;
    }
  }

  // Fake Update
  // updateXpRank() {
  //   this.xpRank.forEach((user) => {
  //     user.xp = Math.round(Math.random() * 1000);
  //   });
  //   this.xpRank.sort((a: any, b: any) => b.xp - a.xp);
  // }

  setChartsColors() {
    if (this.themeService.themeIsLoaded()) {
      // Load Scss Variables
      const primaryColor = this.themeService.getPrimaryColor();
      const accentColor = this.themeService.getAccentColor();
      const warnColor = this.themeService.getWarnColor();

      // LineCharts
      this.lineChartData.datasets.forEach((chart: any, index: any) => {
        if (index == 0) {
          chart.backgroundColor = `rgba(${parseInt(
            primaryColor.substring(1, 3),
            16
          )},${parseInt(primaryColor.substring(3, 5), 16)},${parseInt(
            primaryColor.substring(5, 7),
            16
          )},0.4)`;
          chart.borderColor = primaryColor;
          chart.pointBackgroundColor = primaryColor;
        } else {
          chart.backgroundColor = `rgba(${parseInt(
            accentColor.substring(1, 3),
            16
          )},${parseInt(accentColor.substring(3, 5), 16)},${parseInt(
            accentColor.substring(5, 7),
            16
          )},0.4)`;
          chart.borderColor = accentColor;
          chart.pointBackgroundColor = accentColor;
        }
      });

      // PieCharts
      if (this.pieChartData.datasets[0].backgroundColor) {
        this.pieChartData.datasets[0].backgroundColor = [
          primaryColor,
          accentColor,
          warnColor,
        ];
        this.pieChartData.datasets[0].hoverBackgroundColor = [
          primaryColor,
          accentColor,
          warnColor,
        ];
      }
    }
  }

  generateFakeData(startDate: string, endDate: string) {
    const currentDate = new Date(startDate);
    const lastDate = new Date(endDate);
    const data = [];

    while (currentDate <= lastDate) {
      const randomCount = Math.floor(Math.random() * 10);
      for (let i = 0; i < randomCount; i++) {
        const randomMinutes = Math.floor(Math.random() * 10);
        const randomSeconds = Math.floor(Math.random() * 60);
        const randomMilliseconds = Math.floor(Math.random() * 1000);
        data.push({
          id: Math.random().toString(36).substring(7),
          created_at: currentDate.toISOString(),
          updated_at: currentDate.toISOString(),
          duration: {
            minutes: randomMinutes,
            seconds: randomSeconds,
            milliseconds: randomMilliseconds,
          },
          ended_at: currentDate.toISOString(),
        });
      }

      currentDate.setDate(currentDate.getDate() + 1);
    }

    this.processResponseData(data, this.countByEndedAt);

    this.countByEndedAt = this.reverseObjectKeys(this.countByEndedAt);

    this.lineChartData.labels = Object.keys(this.countByEndedAt);
    this.lineChartData.datasets[0].data = Object.keys(this.countByEndedAt).map(date => this.countByEndedAt[date].count);
    this.lineChartData.datasets[1].data = Object.keys(this.countByEndedAt).map(date => this.countByEndedAt[date].averageDuration);

    // Update Charts
    this.charts?.forEach((child) => {
      child.chart?.update();
    });
  }

  /*  -------------------------------- / 🛠️ Tools --------------------------------  */
}

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { AuthService } from '@core/services/auth.service';
import { Question } from '@data/models/question.model';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { timer } from 'rxjs';

@Component({
  selector: 'app-sg-questions-management',
  templateUrl: './sg-questions-management.component.html',
  styleUrl: './sg-questions-management.component.scss',
})
export class SgQuestionsManagementComponent {
  @Input() question: any;
  @Input() isLastStep: boolean = false;
  @Input() autoSubmit?: boolean = false;
  @Output() getAnswers = new EventEmitter<any>();

  @ViewChild('submitBtn') submitBtnElement!: MatButton;

  answer: any;

  counter = 0;
  startTime: Date | undefined;
  elapsedTime: number = 0;

  questionForm = new FormGroup({});
  questionFormlyField!: FormlyFieldConfig[];
  submitted = false;
  countSubmitBtn = 0;
  options: FormlyFormOptions = {
    formState: {
      submit: false,
    },
  };
  user$ = this.authService.user;
  userPreferencesSkipExplanation = false;

  constructor(private authService: AuthService) { }

  startTimer() {
    this.startTime = new Date();
  }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.

    if (changes['question']) {
      this.countSubmitBtn = 0;
      this.options.formState['submit'] = false;

      // TMP FIX : Because back can send 2 questions
      let questionList = Array.isArray(changes['question'].currentValue)
        ? [changes['question'].currentValue[0]]
        : [changes['question'].currentValue];

      // Set User preferences for skip explanation
      this.user$.subscribe((user: any) => {
        this.userPreferencesSkipExplanation = user.skip_explanation;
        this.questionFormlyField = this.buildFormlyForm(questionList);
        this.startTimer();
      });
    }

    if (changes['autoSubmit']) {
      if (this.autoSubmit) {
        this.submitQuestion();
      }
    }
  }

  isProfilingQcm() {
    if (Array.isArray(this.question) && this.question.length > 0) { return this.question[0].type == 'qcm_profiling'; }
    return false;
  }

  // Form
  buildFormlyForm(questions: any) {
    let globalFormlyConfig: FormlyFieldConfig[] = [];

    this.questionForm.reset();
    this.questionForm.controls = {};
    this.submitted = false;

    // For Each Question
    questions.forEach((question: Question) => {
      globalFormlyConfig.push(this.buildFormlyQuestion(question));
    });

    return globalFormlyConfig;
  }

  // Question
  buildFormlyQuestion(question: any) {
    let questionFormlyConfig = {};

    // Randomize questions
    if (question.options && Array.isArray(question.options)) {
      // Randomize Qcm  && Sorting
      question.options = question.options.sort(() => 0.5 - Math.random());
    } else if (question.options.values) {
      // Randomize Fill Blank
      question.options.values = question.options.values.sort(
        () => 0.5 - Math.random()
      );
    } else if (question.options.to && question.options.from) {
      // Randomize Matching
      question.options.from = question.options.from.sort(
        () => 0.5 - Math.random()
      );
      question.options.to = question.options.to.sort(() => 0.5 - Math.random());
    }

    switch (question.type) {
      // QCM Profiling
      case 'qcm_profiling':
        questionFormlyConfig = this.addQCMInput(question);
        break;

      // QCM
      case 'qcm':
        questionFormlyConfig = this.addQCMInput(question);
        break;

      // Text With Gap
      case 'fill_in_the_blank':
        questionFormlyConfig = this.addFillBlankInput(question);
        break;

      // Sorting
      case 'sorting':
        questionFormlyConfig = this.addSortingInput(question);
        break;

      // Matching
      case 'matching':
        questionFormlyConfig = this.addMatchingInput(question);
        break;

      default:
        break;
    }
    return questionFormlyConfig;
  }

  // Check Questions Answers
  checkQuestionAnswer(question: Question) { }

  submitQuestion() {
    this.submitted = true;
    this.countSubmitBtn++;
    this.options.formState['submit'] = true;
    this.elapsedTime = new Date().getTime() - this.startTime!.getTime();
    if (this.question[0].type == 'qcm_profiling') {
      this.countSubmitBtn++;
    }

    if (this.countSubmitBtn >= 2) {
      this.answer = {
        values: this.questionForm.value,
        duration: this.elapsedTime,
      };
      this.counter = 0;
      this.getAnswers.emit(this.answer);
      this.elapsedTime = 0;
    } else {
      timer(500).subscribe(() => {
        if (this.userPreferencesSkipExplanation || !this.question[0].explanation || this.question[0].explanation == '') {
          const elementDiv = document.getElementById('downDiv');
          elementDiv?.scrollIntoView({ block: 'end', behavior: 'smooth' });
        } else {
          const explanationCard = document.getElementById('explanationCard');
          explanationCard?.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      });
    }
  }

  /* ------------------------  Questions Types ------------------------ */

  // QCM
  addQCMInput(question: any) {
    return {
      key: question.id,
      type: 'fyyyre-qcm',
      props: {
        label: question.wording ?? ' ',
        placeholder: 'Entrez un text',
        type: 'card',
        css: ['glass-card'],
        multiple: true,
        configuration: question.configuration,
        expectedAnswers: question.expected_answer,
        explanation: question.explanation,
        qcmProfiling: question.type == 'qcm_profiling',
        showExplanation:
          question.type == 'qcm_profiling'
            ? false
            : !this.userPreferencesSkipExplanation,
        required: true,
        options: question.options ?? [],
      },
    };
  }

  // FillBlank
  addFillBlankInput(question: any) {
    return {
      key: question.id,
      type: 'fyyyre-fill-blank',
      props: {
        label: question.wording ?? ' ',
        placeholder: 'Entrez un text',
        type: 'card',
        css: ['glass-card', this.submitted ? 'submitted' : ''],
        multiple: true,
        expectedAnswers: question.expected_answer,
        explanation: question.explanation,
        showExplanation: !this.userPreferencesSkipExplanation,
        required: true,
        options: question.options ?? [],
      },
    };
  }

  // Sorting
  addSortingInput(question: any) {
    return {
      key: question.id,
      type: 'fyyyre-sorting',
      props: {
        label: question.wording ?? ' ',
        placeholder: 'Entrez un text',
        type: 'card',
        css: ['glass-card'],
        multiple: true,
        expectedAnswers: question.expected_answer,
        explanation: question.explanation,
        showExplanation: !this.userPreferencesSkipExplanation,
        required: true,
        options: question.options ?? [],
      },
    };
  }

  // Matching
  addMatchingInput(question: any) {
    return {
      key: question.id,
      type: 'fyyyre-matching',
      props: {
        label: question.wording ?? ' ',
        type: 'card',
        css: ['glass-card'],
        multiple: true,
        expectedAnswers: question.expected_answer,
        explanation: question.explanation,
        showExplanation: !this.userPreferencesSkipExplanation,
        required: true,
        options: question.options ?? [],
      },
      hooks: {
        // Allow auto Validation when user end Game
        onInit: (field: FormlyFieldConfig) => {
          field.form?.get(question.id)?.valueChanges.subscribe((formValue) => {
            if (
              formValue &&
              formValue.length == question.expected_answer.length
            ) {
              setTimeout(() => {
                this.matchingInputAutoValidateAnswer();
              }, 100);
            }
          });
        },
      },
    };
  }
  // Trigger Submit Btn
  matchingInputAutoValidateAnswer() {
    if (this.submitBtnElement) {
      this.submitBtnElement._elementRef.nativeElement.click();
    }
  }
  /* ------------------------  / Questions Types ------------------------ */
}

import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-fyyyre-qcm',
  templateUrl: './fyyyre-qcm.component.html',
  styleUrl: './fyyyre-qcm.component.scss',
})
export class FyyyreQcmComponent extends FieldType<FieldTypeConfig> {

  showExplanation: boolean = false;

  ngOnInit(): void {
    this.field.formControl.setValue([]); // Set form to [] by default, else 'undefined' and bug addOrDeleteToForm() method

    this.showExplanation = this.props['showExplanation'];
  }

  getOptions(options: any) {
    return options ?? [];
  }

  onSelection(e: any, v: any, filter: any) {
    // Update Form with new Value
    this.field.formControl?.setValue(
      v.selected.map((o: any) => (o = { value: o.value.value }))
    );
  }

  formIsSubmit() {
    if (this.props['qcmProfiling'] ?? false) {
      return false;
    }
    return this.options.formState['submit'];
  }

  addOrDeleteToForm(data: any) {
    if (this.field.formControl.value && !this.formIsSubmit()) {
      const findIndex = this.field.formControl.value.findIndex(
        (x: any) => x.value == data.value
      );
      if (findIndex !== -1) {
        this.field.formControl.value.splice(findIndex, 1); // Remove from Form
      } else {
        if (this.props['configuration'] && this.props['configuration']['unique_answer'] == true) {
          this.field.formControl?.setValue([]); // Reset Form
        }
        this.field.formControl?.setValue(
          this.field.formControl?.value.concat({ value: data.value })
        ); // Add to Form
      }
    }
  }

  showAnswers(value: any, cssClass: any) {
    if (this.formIsSubmit()) {
      this.field.formControl.disable(); // Disable Form Control
      if (this.field.props /*&& this.field.props['showExplanation'] == true*/) {
        // Add Css Class to Answers
        if (Array.isArray(this.props['expectedAnswers']) && this.props['expectedAnswers'].length > 0) {
          // Check is expectedAnswers is an Array
          if (
            this.props['expectedAnswers'].some((e) => e.value == value) ||
            this.field.formControl.value.some((e: any) => e.value == value)
          ) {
            if (this.props['expectedAnswers'].some((e) => e.value == value)) {
              return cssClass.concat('good-answer');
            } else {
              return cssClass.concat('bad-answer');
            }
          }
        }
      }
    } else {
      if (this.field.formControl.value) {
        if (
          this.field.formControl.value
            .map((val: any) => val.value)
            .includes(value)
        ) {
          return cssClass.concat('mat-primary-background');
        }
      }
    }
    return cssClass;
  }
}
